import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const getListBucket = createAsyncThunk(
    // action 이름이 고유해야함
    'api/getlist',
    async ({ data }: { data: number }, { rejectWithValue }) =>
        // const res = await lockMindDiaryService(diaryId)
        // return res;
        false,
);
interface responseWrapper {
    res: boolean;
    isLoading: boolean;
}
export const initialState: responseWrapper = {
    res: false,
    isLoading: true,
};
const slice = createSlice({
    name: 'getlist',
    initialState,
    reducers: {
        setRes(state, action: PayloadAction<boolean>) {
            return { ...state, res: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListBucket.pending, (state, action) => {
                console.log('pending');
                return { ...state, isLoading: true };
            })
            .addCase(getListBucket.fulfilled, (state, action) => {
                console.log('lock fulfilled');
                return { ...state, res: action.payload, isLoading: false };
            })
            .addCase(getListBucket.rejected, (state, action) => {
                console.log('rejected');
                return { ...state, res: false, isLoading: false };
            });
    },
});
export const { setRes } = slice.actions;
export default slice.reducer;
