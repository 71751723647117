import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { ReactElement, useEffect } from 'react';

import { postCoupon } from '../../api/couponRegist';
import { ReactComponent as HeaderLogo } from '../../essets/svg/home-logo.svg';
import st from '../../styles/pages/home/home.module.css';
import { LoginBtn, TextInput } from '../../styles/pages/home/home.mui';
import useCouponRegist from '../../zustand/stores/useCouponRegist.zustand';

export default function Home(): ReactElement {
    const { email, setEmail, coupon, setCoupon } = useCouponRegist();
    const { mutate, status } = useMutation({
        mutationKey: ['postCoupon'],
        mutationFn: postCoupon,
        onSuccess(data) {
            setEmail('');
            setCoupon('');
            enqueueSnackbar('등록이 완료되었습니다');
        },
        onError(err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
            });
        },
    });

    const onClick = () => {
        mutate({ code: coupon, email });
    };

    useEffect(() => {
        console.log(status);
    }, [status]);
    return (
        <div className={st.container}>
            <div className={st.header}>
                <HeaderLogo />
            </div>
            <div className={st.verticalScroll}>
                {/* 부모가 flex:block이라 flex로 감싸줌 */}
                <div className={st.flexBox}>
                    <div className={st.titleBox}>쿠폰등록페이지</div>

                    {/* 왼쪽 정렬를 깔끔하게 하기 위해 박스로 묶음 */}
                    <div className={st.fullWidth}>
                        <TextInput
                            value={email}
                            disabled={status === 'pending'}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                            label="이메일"
                            placeholder="등록 할 이메일을 입력해주세요"
                            variant="outlined"
                            sx={{ marginTop: '1.8875rem' }}
                        />
                        <TextInput
                            value={coupon}
                            disabled={status === 'pending'}
                            onChange={(event) => {
                                setCoupon(event.target.value);
                            }}
                            label="쿠폰번호"
                            placeholder="쿠폰등록를 입력해주세요"
                            variant="outlined"
                            sx={{ marginTop: '1rem' }}
                        />
                        <strong className={st.listTitle}>유의사항</strong>
                        <ul>
                            <li>등록기간이 지난 쿠폰은 등록이 불가합니다.</li>
                            <li>한번 등록한 쿠폰은 재등록이 불가합니다.</li>
                            <li>
                                일련번호 등록 과정에서 발생하는 오류문의는
                                마음편의점 마이페이지 {'>'} 고객센터로
                                문의하세요.
                            </li>
                            <li>
                                쿠폰 분실에 대한 책임은 소지자에게 있으며,
                                분실된 일련번호 또는 고객의 실수로 사용된
                                일련번호에 대해 보상해드리지 않습니다.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={st.loginBtn}>
                <LoginBtn
                    disabled={status === 'pending'}
                    variant="contained"
                    onClick={onClick}
                >
                    쿠폰등록
                </LoginBtn>
            </div>
        </div>
    );
}
