import { create } from 'zustand';

interface initialzeApp {
    email: string;
    coupon: string;
    setEmail(data: string): void;
    setCoupon(value: string): void;
}

const useCouponRegist = create<initialzeApp>()((set, get) => ({
    email: '',
    coupon: '',
    setEmail(data: string) {
        set({
            email: data,
        });
    },
    setCoupon(data: string) {
        set({
            coupon: data,
        });
    },
}));

export default useCouponRegist;
