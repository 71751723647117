import { getFunctions, httpsCallable } from 'firebase/functions';

import config from '../config/config';
import callApi from '../util/callApi';
import { readUserUid } from '../util/readUserUid';
import {
    couponRegistArg,
    couponRegistRequestBody,
    postCouponRequestBody,
} from './types';

const method = 'PATCH';
export async function couponRegist({
    coupon,
    email,
}: couponRegistArg): Promise<boolean> {
    try {
        const firebaseCall = httpsCallable<couponRegistRequestBody, boolean>(
            getFunctions(undefined, config.gcpRegion),
            config.apiUrl.useTheCoupon,
        );

        return (
            await firebaseCall({
                secret_key: config.secret_key,
                uid: readUserUid(),
                coupon,
                email,
            })
        ).data;
    } catch (err) {
        if (err instanceof Error) {
            throw new Error(err.message);
        }
        throw new Error(String(err));
    }
}

export function postCoupon(body: postCouponRequestBody) {
    return callApi({
        url: config.apiUrl.useTheCoupon,
        method,
        body,
    });
}
