// @mui/meterial 라이브러리 추천
import { Button, styled, TextField } from '@mui/material';

export const TextInput = styled(TextField)(
    ({ theme }) => `
    width: 100%;
    height: 3.5rem;
    align-self:center;

    /* focus 되기전 큰 상태의 label text */
    & .MuiInputLabel-root{
        color:var(--black-2);
    }

    /* focus 되었을 때 작아진 라벨 text  */
    & .MuiInputLabel-root.Mui-focused{
        color: var(--black-2);
    }
    & .MuiInput-underline{
        outline-color:red;
    }

    /* err text color */
    & .MuiFormHelperText-root{
        color:red;
    }
    & .MuiOutlinedInput-root {
        /* 호버 안했을 때 테두리 */
        & fieldset {
            border-color: var(--black-2);
        }
        /* hover했을 때 */
        &:hover fieldset {
            border-color: #B2BAC2;
        }
        /* 클릭했을 때 테두리 */
        &.Mui-focused fieldset {
            border-color: #6F7E8C;
        }
    }
`,
);
export const LoginBtn = styled(Button)(
    ({ theme }) => `
    width: 100%;
    height: 3.5rem;
    margin-bottom: 20px;

    background-color: var(--purple-2);

    
    &:hover{
        background-color: var(--purple-3);
    }
`,
);
