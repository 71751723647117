import config from '../config/config';

type apiMethod = 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH';
interface CallApi {
    url: string;
    body?: any;
    method: apiMethod;
}
export default async function callApi<T>({
    url,
    body,
    method,
}: CallApi): Promise<T> {
    const init: RequestInit = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: body ? JSON.stringify(body) : undefined,
    };
    const res = await fetch(config.api + url, init);
    // 에러면 에러 반환
    if (!res.ok) {
        throw new Error(((await res.json()) as Error).message);
        // throw new Error(res.status.toString());
    }
    // 200이 아닌 성공은 boolean 반환
    if (res.status !== 200) {
        return res.ok as T;
    }
    // 200인 성공은 body반환
    return res.json();
}
