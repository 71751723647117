const config = {
    // api: 'http://192.168.0.67:3000',
    api: 'https://mindstation.kr',
    apiUrl: {
        // 쿠폰 등록
        useTheCoupon: '/api/v1/coupons/redemption',
    },
    gcpRegion: 'asia-northeast3',
    secret_key: 'counselor_secret',
    firebaseConfig: {
        apiKey: 'AIzaSyCN3wKL6-SpfHhkRdCZ0EA9T3CSXtSKhXM',
        authDomain: 'mindstation-v2.firebaseapp.com',
        projectId: 'mindstation-v2',
        storageBucket: 'mindstation-v2.appspot.com',
        messagingSenderId: '326188834853',
        appId: '1:326188834853:web:01fdc921ce5d5a00625fcc',
        measurementId: 'G-SM177NEKQ0',
    },
};
export default config;
